import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_STOCK_BALANCE, ADD_NEW_STORE_ITEM, DELETE_STOCK_BALANCE, DELETE_STORE_ITEM, GET_STOCK_BALANCE, GET_STOCK_BALANCE_COMBO, GET_STORE_ITEMS } from "./actionTypes"
import {
  getStockBalanceSuccess,
  getStockBalanceFail,
  addStockBalanceFail,
  addStockBalanceSuccess,
  deleteStockBalanceSuccess,
  deleteStockBalanceFail,
  getStockBalanceComboSuccess,
  getStockBalanceComboFail
} from "./actions"
import { getStockBalance, addStockBalance, deleteStockBalance, getStockBalanceCombo } from "../../helpers/stock_balance_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchStockBalance({ payload: cdate }) {
  try {
    const response = yield call(getStockBalance)
    yield put(getStockBalanceSuccess(response.StoreBalances))
  } catch (error) {
    yield put(getStockBalanceFail(error))
  }
}

function* fetchStockBalanceCombo() {
  try {
    const response = yield call(getStockBalanceCombo)
    yield put(getStockBalanceComboSuccess(response.StoreItems))
  } catch (error) {
    yield put(getStockBalanceComboFail(error))
  }
}

function* onDeleteStockBalance({ payload: StockBalance }) {
  try {
    const response = yield call(deleteStockBalance, StockBalance)
    yield put(deleteStockBalanceSuccess(response.StoreEntry.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStockBalanceFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddStockBalance({ payload: StockBalance }) {
  try {
    const response = yield call(addStockBalance, StockBalance)
    yield put(addStockBalanceSuccess(response.StoreEntry))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addStockBalanceFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* StockBalanceSaga() {
  yield takeEvery(GET_STOCK_BALANCE, fetchStockBalance)
  yield takeEvery(ADD_NEW_STOCK_BALANCE, onAddStockBalance)
  yield takeEvery(DELETE_STOCK_BALANCE, onDeleteStockBalance)
  yield takeEvery(GET_STOCK_BALANCE_COMBO, fetchStockBalanceCombo)
}

export default StockBalanceSaga;

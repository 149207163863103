import {
  GET_STORE_ITEMS,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMS_FAIL,
  ADD_NEW_STORE_ITEM,
  ADD_STORE_ITEM_SUCCESS,
  ADD_STORE_ITEM_FAIL,
  UPDATE_STORE_ITEM,
  UPDATE_STORE_ITEM_SUCCESS,
  UPDATE_STORE_ITEM_FAIL,
  DELETE_STORE_ITEM,
  DELETE_STORE_ITEM_SUCCESS,
  DELETE_STORE_ITEM_FAIL,
  GET_STORE_ITEM,
  GET_STORE_ITEM_SUCCESS,
  GET_STORE_ITEM_FAIL,
  GET_STORE_ITEMSCOMBO_SUCCESS,
  GET_STORE_ITEMSCOMBO_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  storeitem: [],
  storeitemCombo: [],
  error: {},
  loading: false
}

const storeitems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORE_ITEMSCOMBO_SUCCESS:
      return {
        ...state,
        storeitemCombo: action.payload,
        loading: false
      }
      break
    case GET_STORE_ITEMSCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
        
      }
      break
    case GET_STORE_ITEMS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STORE_ITEMS_SUCCESS:
      return {
        ...state,
        storeitem: action.payload,
        loading: false
      }
      break
    case GET_STORE_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_STORE_ITEM:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_STORE_ITEM_SUCCESS:

      return {
        ...state,
        storeitem: [...state.storeitem, action.payload],
        loading: false
      }
      break
    case ADD_STORE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case UPDATE_STORE_ITEM:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STORE_ITEM_SUCCESS:
      return {
        ...state,
        storeitem: action.payload,
        loading: false
      }
      break
    case UPDATE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        storeitem: state.storeitem.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_STORE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case DELETE_STORE_ITEM:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        storeitem: state.storeitem.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_STORE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STORE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default storeitems

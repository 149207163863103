import {
  GET_STOCK_UPDATE,
  GET_STOCK_UPDATE_FAIL,
  GET_STOCK_UPDATE_SUCCESS,
  ADD_NEW_STOCK_UPDATE,
  ADD_STOCK_UPDATE_SUCCESS,
  ADD_STOCK_UPDATE_FAIL,
  DELETE_STOCK_UPDATE,
  DELETE_STOCK_UPDATE_SUCCESS,
  DELETE_STOCK_UPDATE_FAIL,
} from "./actionTypes"

export const getStockUpdate = (cdate) => ({
  type: GET_STOCK_UPDATE,
  payload: cdate,
})

export const getStockUpdateSuccess = storeitem => ({
  type: GET_STOCK_UPDATE_SUCCESS,
  payload: storeitem,
})

export const addNewStockUpdate = storeitem => ({
  type: ADD_NEW_STOCK_UPDATE,
  payload: storeitem,
})

export const addStockUpdateSuccess = storeitem => ({
  type: ADD_STOCK_UPDATE_SUCCESS,
  payload: storeitem,
})

export const addStockUpdateFail = error => ({
  type: ADD_STOCK_UPDATE_FAIL,
  payload: error,
})

export const getStockUpdateFail = error => ({
  type: GET_STOCK_UPDATE_FAIL,
  payload: error,
})

export const deleteStockUpdate = storeitem => ({
  type: DELETE_STOCK_UPDATE,
  payload: storeitem,
})

export const deleteStockUpdateSuccess = storeitem => ({
  type: DELETE_STOCK_UPDATE_SUCCESS,
  payload: storeitem,
})

export const deleteStockUpdateFail = error => ({
  type: DELETE_STOCK_UPDATE_FAIL,
  payload: error,
})

/* USERS */
export const GET_STOCK_UPDATES = "GET_STOCK_UPDATES"
export const GET_STOCK_UPDATES_SUCCESS = "GET_STOCK_UPDATES_SUCCESS"
export const GET_STOCK_UPDATES_FAIL = "GET_STOCK_UPDATES_FAIL"

/* CATEGORS PROFILE */
export const GET_STOCK_UPDATE = "GET_STOCK_UPDATE"
export const GET_STOCK_UPDATE_SUCCESS = "GET_STOCK_UPDATE_SUCCESS"
export const GET_STOCK_UPDATE_FAIL = "GET_STOCK_UPDATE_FAIL"

/**
 * add user
 */
export const ADD_NEW_STOCK_UPDATE = "ADD_NEW_STOCK_UPDATE"
export const ADD_STOCK_UPDATE_SUCCESS = "ADD_STOCK_UPDATE_SUCCESS"
export const ADD_STOCK_UPDATE_FAIL = "ADD_STOCK_UPDATE_FAIL"

/**
 * Delete user
 */
export const DELETE_STOCK_UPDATE = "DELETE_STOCK_UPDATE"
export const DELETE_STOCK_UPDATE_SUCCESS = "DELETE_STOCK_UPDATE_SUCCESS"
export const DELETE_STOCK_UPDATE_FAIL = "DELETE_STOCK_UPDATE_FAIL"

import {
  GET_STOCK_ENTRY,
  GET_STOCK_ENTRY_FAIL,
  GET_STOCK_ENTRY_SUCCESS,
  ADD_NEW_STOCK_ENTRY,
  ADD_STOCK_ENTRY_SUCCESS,
  ADD_STOCK_ENTRY_FAIL,
  DELETE_STOCK_ENTRY,
  DELETE_STOCK_ENTRY_SUCCESS,
  DELETE_STOCK_ENTRY_FAIL,
} from "./actionTypes"

export const getStockEntry = (cdate) => ({
  type: GET_STOCK_ENTRY,
  payload: cdate,
})

export const getStockEntrySuccess = storeitem => ({
  type: GET_STOCK_ENTRY_SUCCESS,
  payload: storeitem,
})

export const addNewStockEntry = storeitem => ({
  type: ADD_NEW_STOCK_ENTRY,
  payload: storeitem,
})

export const addStockEntrySuccess = storeitem => ({
  type: ADD_STOCK_ENTRY_SUCCESS,
  payload: storeitem,
})

export const addStockEntryFail = error => ({
  type: ADD_STOCK_ENTRY_FAIL,
  payload: error,
})

export const getStockEntryFail = error => ({
  type: GET_STOCK_ENTRY_FAIL,
  payload: error,
})

export const deleteStockEntry = storeitem => ({
  type: DELETE_STOCK_ENTRY,
  payload: storeitem,
})

export const deleteStockEntrySuccess = storeitem => ({
  type: DELETE_STOCK_ENTRY_SUCCESS,
  payload: storeitem,
})

export const deleteStockEntryFail = error => ({
  type: DELETE_STOCK_ENTRY_FAIL,
  payload: error,
})

import {
  ADD_NEW_STOCK_ENTRY,
  ADD_STOCK_ENTRY_SUCCESS,
  ADD_STOCK_ENTRY_FAIL,
  DELETE_STOCK_ENTRY,
  DELETE_STOCK_ENTRY_SUCCESS,
  DELETE_STOCK_ENTRY_FAIL,
  GET_STOCK_ENTRY,
  GET_STOCK_ENTRY_SUCCESS,
  GET_STOCK_ENTRY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  stockentry: [],
  stockentryCombo: [],
  error: {},
  loading: false
}

const stockentry = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STOCK_ENTRY:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STOCK_ENTRY_SUCCESS:
      return {
        ...state,
        stockentry: action.payload,
        loading: false
      }
      break
    case GET_STOCK_ENTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_STOCK_ENTRY:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_STOCK_ENTRY_SUCCESS:

      return {
        ...state,
        stockentry: [...state.stockentry, action.payload],
        loading: false
      }
      break
    case ADD_STOCK_ENTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STOCK_ENTRY_SUCCESS:
      return {
        ...state,
        stockentry: action.payload,
        loading: false
      }
      break
    case DELETE_STOCK_ENTRY:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_STOCK_ENTRY_SUCCESS:
      return {
        ...state,
        stockentry: state.stockentry.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_STOCK_ENTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STOCK_ENTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default stockentry

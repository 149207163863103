import React from "react";
import { Navigate } from "react-router-dom";

// Profile

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import StoreMetric from "pages/StoreMetric";
import StoreCategory from "pages/StoreCategory";
import StoreItem from "pages/StoreItem";
import TablePayments from "pages/Payments/TablePayments";
import StockEntry from "pages/StockEntry";
import StockUpdate from "pages/StockUpdate";
import StockReport from "pages/StockReport";

const authProtectedRoutes = [

  { path : "/store-metric", component: <StoreMetric/> },

  { path : "/store-category", component: <StoreCategory/> },

  { path : "/store-item", component: <StoreItem/> },

  { path: "/table-payment", component: <TablePayments/>},

  { path: "/stock-entry", component: <StockEntry/>},

  { path: "/stock-update", component: <StockUpdate/>},

  { path: "/stock-report", component: <StockReport/>},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/store-item" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> }
];

export { authProtectedRoutes, publicRoutes };

import { act } from "@testing-library/react"
import {
  ADD_NEW_STOCK_BALANCE,
  ADD_STOCK_BALANCE_SUCCESS,
  ADD_STOCK_BALANCE_FAIL,
  DELETE_STOCK_BALANCE,
  DELETE_STOCK_BALANCE_SUCCESS,
  DELETE_STOCK_BALANCE_FAIL,
  GET_STOCK_BALANCE,
  GET_STOCK_BALANCE_SUCCESS,
  GET_STOCK_BALANCE_FAIL,
  GET_STOCK_BALANCE_COMBO_FAIL,
  GET_STOCK_BALANCE_COMBO_SUCCESS,
  ADD_STOCK_BALANCE_COMBO,
  REMOVE_STOCK_BALANCE_COMBO
} from "./actionTypes"

const INIT_STATE = {
  stockbalance: [],
  stockbalanceCombo: [],
  error: {},
  loading: false
}

const stockbalance = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STOCK_BALANCE:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STOCK_BALANCE_SUCCESS:
      return {
        ...state,
        stockbalance: action.payload,
        loading: false
      }
      break
    case GET_STOCK_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_STOCK_BALANCE:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_STOCK_BALANCE_SUCCESS:

      return {
        ...state,
        stockbalance: [...state.stockbalance, action.payload],
        loading: false
      }
      break
    case ADD_STOCK_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STOCK_BALANCE_SUCCESS:
      return {
        ...state,
        stockbalance: action.payload,
        loading: false
      }
      break
    case DELETE_STOCK_BALANCE:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_STOCK_BALANCE_SUCCESS:
      return {
        ...state,
        stockbalance: state.stockbalance.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_STOCK_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STOCK_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
      case GET_STOCK_BALANCE_COMBO_SUCCESS:
      return {
        ...state,
        stockbalanceCombo: action.payload,
        loading: false
      }
      break
    case GET_STOCK_BALANCE_COMBO_FAIL:
      return {
        ...state,
        error: action.payload,
        
      }
      break
      case ADD_STOCK_BALANCE_COMBO:
      // Log the current state and action payload for debugging
      console.log("Before update: ", state.stockbalanceCombo);
      console.log("Action payload: ", action.payload);

      const updatedStockbalanceCombo = state.stockbalanceCombo.map(item => {
        if (item.value.toString() === action.payload.id.toString()) {
          console.log("Updating item: ", item);
          const calc = item.available - action.payload.qty
          const updatedItem = {
            ...item,
            available: item.available - action.payload.qty
          };
          console.log("Updated item: ", updatedItem);

          return updatedItem;
        }
        return item;
      });

      // Log the updated stockbalanceCombo after the update
      console.log("After update: ", updatedStockbalanceCombo);

      return {
        ...state,
        stockbalanceCombo: updatedStockbalanceCombo,
        loading: false
      }
      break
      case REMOVE_STOCK_BALANCE_COMBO:
      // Log the current state and action payload for debugging
      console.log("Before update: ", state.stockbalanceCombo);
      console.log("Action payload: ", action.payload);

      const StockbalanceCombo = state.stockbalanceCombo.map(item => {
        if (item.value.toString() === action.payload.id.toString()) {
          // Log the item being updated
          console.log("Updating item: ", item);

          // Update available and log the new value
          const updatedItem = {
            ...item,
            available: item.available + action.payload.qty
          };
          console.log("Updated item: ", updatedItem);

          return updatedItem;
        }
        return item;
      });

      // Log the updated stockbalanceCombo after the update
      console.log("After update: ", StockbalanceCombo);

      return {
        ...state,
        stockbalanceCombo: StockbalanceCombo,
        loading: false
      }
      break
    default:
      return state
  }
}

export default stockbalance

import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_STOCK_UPDATE, ADD_NEW_STORE_ITEM, DELETE_STOCK_UPDATE, DELETE_STORE_ITEM, GET_STOCK_UPDATE, GET_STORE_ITEMS } from "./actionTypes"
import {
  getStockUpdateSuccess,
  getStockUpdateFail,
  addStockUpdateFail,
  addStockUpdateSuccess,
  deleteStockUpdateSuccess,
  deleteStockUpdateFail,
} from "./actions"
import { getStockUpdate, addStockUpdate, deleteStockUpdate } from "../../helpers/stock_update_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { addStockBalanceCombo, removeStockBalanceCombo } from "store/stockbalance/actions";

function* fetchStockUpdate({ payload: cdate }) {
  try {
    const response = yield call(getStockUpdate,cdate)
    yield put(getStockUpdateSuccess(response.StoreEntrys))
  } catch (error) {
    yield put(getStockUpdateFail(error))
  }
}

function* onDeleteStockUpdate({ payload: StockUpdate }) {
  try {
    const response = yield call(deleteStockUpdate, StockUpdate)
    yield put(deleteStockUpdateSuccess(response.StoreEntry.id))
    yield put(removeStockBalanceCombo({qty:response.StoreEntry.qty, id:response.StoreEntry.store_item}))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStockUpdateFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddStockUpdate({ payload: StockUpdate }) {
  try {
    const response = yield call(addStockUpdate, StockUpdate)
    yield put(addStockUpdateSuccess(response.StoreEntry))
    yield put(addStockBalanceCombo({qty:response.StoreEntry.qty, id:response.StoreEntry.store_item._id}))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(addStockUpdateFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* StockUpdateSaga() {
  yield takeEvery(GET_STOCK_UPDATE, fetchStockUpdate)
  yield takeEvery(ADD_NEW_STOCK_UPDATE, onAddStockUpdate)
  yield takeEvery(DELETE_STOCK_UPDATE, onDeleteStockUpdate)
}

export default StockUpdateSaga;

import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import categories from "./category/reducer";
import tags from "./tag/reducer";
import coupans from "./coupan/reducer";
import subscriptions from "./subscription/reducer";
import storys from "./story/reducer";
import chapters from "./chapter/reducer";
import slides from "./slides/reducer";
import homes from "./home/reducer";
import notifications from "./notification/reducer";
import subscribers from "./subscriber/reducer";
import users from "./user/reducer";
import dashboard from "./dashboard/reducer";
import counters from "./counter/reducer";
import storeMetrics from "./storemetric/reducer";
import storeCategorys from "./storecategory/reducer";
import storeitems from "./storeitem/reducer";
import fooditems from "./fooditem/reducer";
import roles from "./role/reducer";
import orders from "./order/reducer";
import stockentry from "./stockentry/reducer";
import stockupdate from "./stockupdate/reducer";
import stockbalance from "./stockbalance/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  
  categories,
  tags,
  coupans,
  subscriptions,
  storys,
  chapters,
  slides,
  homes,
  notifications,
  subscribers,
  users,
  dashboard,
  counters,
  storeMetrics,
  storeCategorys,
  storeitems,
  fooditems,
  roles,
  orders,
  stockentry,
  stockupdate,
  stockbalance
});

export default rootReducer;

/* USERS */
export const GET_STOCK_BALANCES = "GET_STOCK_BALANCES"
export const GET_STOCK_BALANCES_SUCCESS = "GET_STOCK_BALANCES_SUCCESS"
export const GET_STOCK_BALANCES_FAIL = "GET_STOCK_BALANCES_FAIL"

/* CATEGORS PROFILE */
export const GET_STOCK_BALANCE = "GET_STOCK_BALANCE"
export const GET_STOCK_BALANCE_SUCCESS = "GET_STOCK_BALANCE_SUCCESS"
export const GET_STOCK_BALANCE_FAIL = "GET_STOCK_BALANCE_FAIL"

/**
 * add user
 */
export const ADD_NEW_STOCK_BALANCE = "ADD_NEW_STOCK_BALANCE"
export const ADD_STOCK_BALANCE_SUCCESS = "ADD_STOCK_BALANCE_SUCCESS"
export const ADD_STOCK_BALANCE_FAIL = "ADD_STOCK_BALANCE_FAIL"

/**
 * Delete user
 */
export const DELETE_STOCK_BALANCE = "DELETE_STOCK_BALANCE"
export const DELETE_STOCK_BALANCE_SUCCESS = "DELETE_STOCK_BALANCE_SUCCESS"
export const DELETE_STOCK_BALANCE_FAIL = "DELETE_STOCK_BALANCE_FAIL"


export const GET_STOCK_BALANCE_COMBO = "GET_STOCK_BALANCE_COMBO"
export const GET_STOCK_BALANCE_COMBO_FAIL = "GET_STOCK_BALANCE_COMBO_FAIL"
export const GET_STOCK_BALANCE_COMBO_SUCCESS = "GET_STOCK_BALANCE_COMBO_SUCCESS"

export const ADD_STOCK_BALANCE_COMBO = "ADD_STOCK_BALANCE_COMBO"
export const REMOVE_STOCK_BALANCE_COMBO = "REMOVE_STOCK_BALANCE_COMBO"
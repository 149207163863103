import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_STOCK_ENTRY, ADD_NEW_STORE_ITEM, DELETE_STOCK_ENTRY, DELETE_STORE_ITEM, GET_STOCK_ENTRY, GET_STORE_ITEMS } from "./actionTypes"
import {
  getStockEntrySuccess,
  getStockEntryFail,
  addStockEntryFail,
  addStockEntrySuccess,
  deleteStockEntrySuccess,
  deleteStockEntryFail,
} from "./actions"
import { getStockEntry, addStockEntry, deleteStockEntry } from "../../helpers/stock_entry_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchStockEntry({ payload: cdate }) {
  try {
    const response = yield call(getStockEntry,cdate)
    yield put(getStockEntrySuccess(response.StoreStocks))
  } catch (error) {
    yield put(getStockEntryFail(error))
  }
}

function* onDeleteStockEntry({ payload: StockEntry }) {
  try {
    const response = yield call(deleteStockEntry, StockEntry)
    yield put(deleteStockEntrySuccess(response.StoreStock.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStockEntryFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddStockEntry({ payload: StockEntry }) {
  try {
    const response = yield call(addStockEntry, StockEntry)
    yield put(addStockEntrySuccess(response.StoreStock))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addStockEntryFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* StockEntrySaga() {
  yield takeEvery(GET_STOCK_ENTRY, fetchStockEntry)
  yield takeEvery(ADD_NEW_STOCK_ENTRY, onAddStockEntry)
  yield takeEvery(DELETE_STOCK_ENTRY, onDeleteStockEntry)
}

export default StockEntrySaga;

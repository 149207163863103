import {
  GET_STOCK_BALANCE,
  GET_STOCK_BALANCE_FAIL,
  GET_STOCK_BALANCE_SUCCESS,
  ADD_NEW_STOCK_BALANCE,
  ADD_STOCK_BALANCE_SUCCESS,
  ADD_STOCK_BALANCE_FAIL,
  DELETE_STOCK_BALANCE,
  DELETE_STOCK_BALANCE_SUCCESS,
  DELETE_STOCK_BALANCE_FAIL,
  GET_STOCK_BALANCE_COMBO,
  GET_STOCK_BALANCE_COMBO_FAIL,
  GET_STOCK_BALANCE_COMBO_SUCCESS,
  ADD_STOCK_BALANCE_COMBO,
  REMOVE_STOCK_BALANCE_COMBO
} from "./actionTypes"

export const getStockBalance = () => ({
  type: GET_STOCK_BALANCE,
})

export const getStockBalanceSuccess = storeitem => ({
  type: GET_STOCK_BALANCE_SUCCESS,
  payload: storeitem,
})

export const addNewStockBalance = storeitem => ({
  type: ADD_NEW_STOCK_BALANCE,
  payload: storeitem,
})

export const addStockBalanceSuccess = storeitem => ({
  type: ADD_STOCK_BALANCE_SUCCESS,
  payload: storeitem,
})

export const addStockBalanceFail = error => ({
  type: ADD_STOCK_BALANCE_FAIL,
  payload: error,
})

export const getStockBalanceFail = error => ({
  type: GET_STOCK_BALANCE_FAIL,
  payload: error,
})

export const deleteStockBalance = storeitem => ({
  type: DELETE_STOCK_BALANCE,
  payload: storeitem,
})

export const deleteStockBalanceSuccess = storeitem => ({
  type: DELETE_STOCK_BALANCE_SUCCESS,
  payload: storeitem,
})

export const deleteStockBalanceFail = error => ({
  type: DELETE_STOCK_BALANCE_FAIL,
  payload: error,
})
export const getStockBalanceCombo = () => ({
  type: GET_STOCK_BALANCE_COMBO,
})

export const getStockBalanceComboSuccess = storeitem => ({
  type: GET_STOCK_BALANCE_COMBO_SUCCESS,
  payload: storeitem,
})

export const getStockBalanceComboFail = error => ({
  type: GET_STOCK_BALANCE_COMBO_FAIL,
  payload: error,
})

export const addStockBalanceCombo = storeitem => ({
  type: ADD_STOCK_BALANCE_COMBO,
  payload: storeitem,
})

export const removeStockBalanceCombo = storeitem => ({
  type: REMOVE_STOCK_BALANCE_COMBO,
  payload: storeitem,
})
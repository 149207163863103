import React, { useEffect, useState, useRef, useMemo } from "react";
// import { getStoreMetricCombo } from "../../store/storemetric/actions";
import { getStoreItemCombo } from "store/storeitem/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";

const HeadSelect = ({ OnSelect, defaultValue, error, isSubmitted, isMulti }) => {
    // const dispatch = useDispatch();
    
    const [selectedStoreItem, setselectedStoreItem] = useState([]);
    const [touchedCategory, setTouchedCategory] = useState(false);
    const storeItems = [
        { value: "Tea", label: "Tea" },
        { value: "Tiffine", label: "Tiffine" },
        { value: "India", label: "India" },
        { value: "Tandoori", label: "Tandoori" },
        { value: "Chinese", label: "Chinese" },
        { value: "Chat", label: "Chat" },
        { value: "Juice", label: "Juice" },
        { value: "Mythreya Travels", label: "Mythreya Travels" },
    ]
    // const selectContactsState = (state) => state.storeitems;
    
    // const ContactsProperties = createSelector(
    //     selectContactsState,
    //     (StoreItem) => ({
    //         storeItems: StoreItem.storeitemCombo,
    //         loading: StoreItem.loading
    //     })
    // );
    // const { storeItems, loading } = useSelector(ContactsProperties);
    const handleSelect = (val) => {
        setselectedStoreItem(val)
        setTouchedCategory(true);
        OnSelect(val)
    }

    // useEffect(() => {
        
    //     if (storeItems && !storeItems.length) {
    //         dispatch(getStoreItemCombo());
    //     }
    //     setTouchedCategory(false);
    // }, [dispatch, storeItems]);

    useEffect(() => {
        if(!isMulti){
            if (storeItems && storeItems.length != 0 && defaultValue) {
                const defaultCategory = storeItems.find((option) => option.value === defaultValue);
                console.log(defaultValue)
                if (defaultCategory) {
                    setselectedStoreItem(defaultCategory);
                }
            }
            // if (!defaultValue) {
            //     setselectedStoreItem(null);
            // }
        }else{
            
            if (storeItems && storeItems.length && defaultValue.length != 0) {
                let storeMetric = storeItems.filter(storeMetric => defaultValue.some(value => value.value === storeMetric.value));
                // console.log(tag)
                setselectedStoreItem(storeMetric)
            }
            if (defaultValue.length == 0) {
                setselectedStoreItem([])
            }
        }
    }, [storeItems, defaultValue]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={storeItems}
                value={selectedStoreItem}
                onChange={handleSelect}
                isMulti={isMulti}
                isSearchable={true}
            />
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default HeadSelect
import {
  ADD_NEW_STOCK_UPDATE,
  ADD_STOCK_UPDATE_SUCCESS,
  ADD_STOCK_UPDATE_FAIL,
  DELETE_STOCK_UPDATE,
  DELETE_STOCK_UPDATE_SUCCESS,
  DELETE_STOCK_UPDATE_FAIL,
  GET_STOCK_UPDATE,
  GET_STOCK_UPDATE_SUCCESS,
  GET_STOCK_UPDATE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  stockupdate: [],
  stockupdateCombo: [],
  error: {},
  loading: false
}

const stockupdate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STOCK_UPDATE:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STOCK_UPDATE_SUCCESS:
      return {
        ...state,
        stockupdate: action.payload,
        loading: false
      }
      break
    case GET_STOCK_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_STOCK_UPDATE:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_STOCK_UPDATE_SUCCESS:

      return {
        ...state,
        stockupdate: [...state.stockupdate, action.payload],
        loading: false
      }
      break
    case ADD_STOCK_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STOCK_UPDATE_SUCCESS:
      return {
        ...state,
        stockupdate: action.payload,
        loading: false
      }
      break
    case DELETE_STOCK_UPDATE:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_STOCK_UPDATE_SUCCESS:
      return {
        ...state,
        stockupdate: state.stockupdate.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_STOCK_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STOCK_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
      
    default:
      return state
  }
}

export default stockupdate

import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_STORE_ITEM, DELETE_STORE_ITEM, GET_STORE_ITEMS, UPDATE_STORE_ITEM, GET_STORE_ITEMSCOMBO } from "./actionTypes"
import {
  getStoreItemsSuccess,
  getStoreItemsFail,
  addStoreItemFail,
  addStoreItemSuccess,
  updateStoreItemSuccess,
  updateStoreItemFail,
  deleteStoreItemSuccess,
  deleteStoreItemFail,
  getStoreItemsComboSuccess,
  getStoreItemsComboFail
} from "./actions"
import { getStoreItem, addStoreItem, updateStoreItem, deleteStoreItem, getStoreItemCombo } from "../../helpers/store_item_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchStoreItem() {
  try {
    const response = yield call(getStoreItem)
    yield put(getStoreItemsSuccess(response.StoreItems))
  } catch (error) {
    yield put(getStoreItemsFail(error))
  }
}

function* fetchStoreItemsCombo() {
  try {
    const response = yield call(getStoreItemCombo)
    yield put(getStoreItemsComboSuccess(response.storeItems))
  } catch (error) {
    yield put(getStoreItemsComboFail(error))
  }
}

function* onUpdateStoreItem({ payload: StoreItem }) {
  try {
    const response = yield call(updateStoreItem, StoreItem)
    yield put(updateStoreItemSuccess(response.StoreItem))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateStoreItemFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteStoreItem({ payload: StoreItem }) {
  try {
    const response = yield call(deleteStoreItem, StoreItem)
    yield put(deleteStoreItemSuccess(response.StoreItem.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStoreItemFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddStoreItem({ payload: StoreItem }) {
  try {
    const response = yield call(addStoreItem, StoreItem)
    yield put(addStoreItemSuccess(response.StoreItem))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addStoreItemFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* StoreItemSaga() {
  yield takeEvery(GET_STORE_ITEMS, fetchStoreItem)
  yield takeEvery(ADD_NEW_STORE_ITEM, onAddStoreItem)
  yield takeEvery(UPDATE_STORE_ITEM, onUpdateStoreItem)
  yield takeEvery(DELETE_STORE_ITEM, onDeleteStoreItem)
  yield takeEvery(GET_STORE_ITEMSCOMBO, fetchStoreItemsCombo)
}

export default StoreItemSaga;
